<script setup lang="ts">
import IconMinusBold from '@assets/icons/patient/minus-bold.svg';
import IconPlusBold from '@assets/icons/patient/plus-bold.svg';
import { computed, toRefs } from 'vue';

type Props = {
  value: number
  disabled?: boolean
  buttonClass?: string
  labelClass?: string
};

const props = withDefaults(defineProps<Props>(), { buttonClass: undefined, labelClass: undefined, disabled: false });
const emit = defineEmits<{
  increase: [],
  decrease: [],
}>();
const { buttonClass, labelClass, value, disabled } = toRefs(props);

const isLockZero = computed(() => value.value <= 0);
const isLockPlus = computed(() => value.value >= 100);

const decrease = () => {
  if (disabled.value || isLockZero.value) {
    return;
  }

  emit('decrease');
};

const increase = () => {
  if (disabled.value || isLockPlus.value) {
    return;
  }

  emit('increase');
};
</script>
<template>
  <div class="grid grid-cols-3 gap-x-5">
    <button
      type="button"
      :disabled="disabled"
      :class="[isLockZero && 'opacity-50', 'counter-button bg-light-grey hover:bg-purple disabled:hover:bg-light-grey', buttonClass]"
      data-testpl="counter-minus"
      @click.prevent="decrease"
    >
      <IconMinusBold class="h-12 w-12 text-white" />
    </button>

    <div
      data-testpl="counter-value"
      :class="[
        'flex items-center justify-center text-18 font-bold text-light-grey',
        labelClass && labelClass
      ]"
    >
      {{ value }}
    </div>

    <button
      type="button"
      :disabled="disabled"
      :class="[isLockPlus && 'opacity-50','counter-button bg-light-grey hover:bg-purple disabled:hover:bg-light-grey', buttonClass]"
      data-testpl="counter-plus"
      @click.prevent="increase"
    >
      <IconPlusBold class="h-12 w-12 text-white" />
    </button>
  </div>
</template>
<style scoped lang="scss">
.counter-button {
  @apply flex h-26 w-26 items-center justify-center rounded-full;
}
</style>
