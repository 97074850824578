<script setup lang="ts">
import CloseIcon from '@assets/icons/close.svg';
import { ref } from 'vue';

const isOpen = ref(false);

const toggleMenu = () => {
  isOpen.value = !isOpen.value;
};
const siteHandle = window.siteHandle;
</script>

<template>
  <div class="base-dialog-wr">
    <Transition name="fade">
      <div
        v-if="isOpen"
        class="base-dialog">
        <div
          v-if="isOpen"
          v-click-outside="toggleMenu"
          class="base-dialog__cont">
          <button
            class="base-dialog__close-btn"
            type="button"
            @click="toggleMenu"
          >
            <CloseIcon class="h-[26px] w-[26px]" />
          </button>
          <nav>
            <slot />
          </nav>
        </div>
      </div>
    </Transition>

    <button
      class="flex items-center justify-center text-purple hover:text-deep-blue"
      type="button"
      @click="toggleMenu"
    >
      <slot name="icon" />
    </button>
  </div>
</template>

<style lang="scss" scoped>

.base-dialog-wr {
  position: relative;
}

.base-dialog {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: auto;
  background: rgba(206, 200, 192, 0.8);
  backdrop-filter: blur(5px);

  @include up('lg') {
    position: absolute;
    left: auto;
    right: -20px;
    top: -20px;
    min-width: 370px;
    width: auto;
    padding: 0;
    height: auto;
    background: none;
    backdrop-filter: none;
    border-radius: 20px;
  }
}
.header {
  .base-dialog {
    @include up('lg') {
      right: -1px;
      top: -1px;
    }
  }
}
.base-dialog__cont {
  position: relative;
  max-width: 370px;
  width: 100%;
  margin: auto;
  padding: 86px 20px 20px 20px;
  border-radius: 20px;
  background-color: var(--color-white);
  box-shadow: $soft-shadow;
}

.base-dialog__close-btn {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  padding: 10px;
}
</style>
