import { apiCart } from '@api/cart';
import { apiQuiz } from '@api/quiz';
import { SessionInfoResponse } from '@models/user';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export type AnalyticProduct = {
  item_name: string;
  price: number;
  quantity: number;
  item_id?: string
  item_brand?: string
  item_category?: string
  item_list_id?: string
  item_list_name?: string
  index?: number
};

export const useAnalyticsStore = defineStore('analytics', () => {
  const aid = ref(localStorage.getItem('aid'));
  const cid = ref(localStorage.getItem('cid'));
  const campaign = ref(localStorage.getItem('campaign'));

  // Fire on the first page of the quiz
  const viewContent = (quizName: string) => {
    // eslint-disable-next-line no-console
    console.log('viewContent', { quizName });

    window.gtag?.('event', 'view_content', { name: quizName });
    window.fbq?.('track', 'ViewContent', { content_name: quizName });
    window.ttq?.track('ViewContent', { content_name: quizName });
  };

  const viewQuizStep = (stepSlug: string, stepName?: string) => {
    // eslint-disable-next-line no-console
    console.log('viewQuizStep', { stepSlug, stepName });
    const eventUuid = crypto.randomUUID();
    window.fbq?.('trackCustom', 'ViewQuizStep', {
      step_slug: stepSlug,
      step_name: stepName,
    }, {
      eventID: eventUuid,
    });

    apiQuiz.trackViewQuizStep({
      stepSlug: stepSlug,
      stepName: stepName,
      eventId: eventUuid,
    });

    window.gtag?.('event', 'view_quiz_step', {
      step_slug: stepSlug,
      step_name: stepName,
      transaction_id: eventUuid,
    });
  };

  const selectQuizAnswer = (answer: string[], stepSlug: string) => {
    // eslint-disable-next-line no-console
    console.log('selectQuizAnswer', { answer, stepSlug });
    const eventUuid = crypto.randomUUID();
    window.fbq?.('trackCustom', 'SelectQuizAnswer', {
      step_value: answer,
      step_slug: stepSlug,
    }, {
      eventID: eventUuid,
    });

    apiQuiz.trackSelectQuizAnswer({
      stepSlug: stepSlug,
      stepValue: answer,
      eventId: eventUuid,
    });

    window.gtag?.('event', 'select_quiz_answer', {
      step_slug: stepSlug,
      step_value: answer,
      transaction_id: eventUuid,
    });
  };

  // Fire when the user looked at the pharmacy
  const viewPharmacy = (categoryHandle: string, categoryName: string, items: AnalyticProduct[]) => {
    // eslint-disable-next-line no-console
    console.log('viewPharmacy', { categoryHandle, categoryName, items });

    window.gtag?.('event', 'view_item_list', {
      item_list_id: categoryHandle,
      item_list_name: categoryName,
      currency: 'USD',
      items,
    });
  };

  // Fire when User has registered
  const createAccount = (info: SessionInfoResponse) => {
    // eslint-disable-next-line no-console
    console.log('createAccount', { aid: aid.value });
    // eslint-disable-next-line no-console
    console.log('createAccount', { cid: cid.value });
    // eslint-disable-next-line no-console
    console.log('createAccount', { campaign: campaign.value });

    window.gtag?.('event', 'create_account', {
      transaction_id: info.id,
    });
    window.fbq?.('track', 'CompleteRegistration', null, {
      eventID: info.id,
    });
  };

  // After you add a product to the cart (either subscription or product)
  const addToCart = (
    items: AnalyticProduct[],
    value = 0,
    eventId: string|null = null,
  ) => {
    // eslint-disable-next-line no-console
    console.log('addToCart', { items, value, eventId });

    window.gtag?.('event', 'add_to_cart', {
      currency: 'USD',
      value,
      items: items.map((item) => ({
        item_name: item.item_name,
        price: item.price,
        quantity: item.quantity,
      })),
      transaction_id: eventId,
    });

    window.fbq?.('track', 'AddToCart', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      value,
    }, {
      eventID: eventId,
    });

    window.ttq?.track('AddToCart', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      value,
    });

    apiCart.trackAddToCart({
      eventId: eventId || '',
      currency: 'USD',
      value,
      items: items.map((item) => ({
        id: item.item_id,
        name: item.item_name,
        category: item.item_list_id,
        price: item.price,
        quantity: item.quantity,
      })),
    });
  };

  // After you remove a product to the cart  - click on minus
  const removeFromCart = (items: AnalyticProduct[], value = 0, eventId: string|null = null)  => {
    // eslint-disable-next-line no-console
    console.log('removeFromCart', { items, value });

    window.gtag?.('event', 'remove_from_cart', {
      currency: 'USD',
      value: value,
      items,
      transaction_id: eventId,
    });

    window.fbq?.('trackCustom', 'RemoveFromCart', {
      currency: 'USD',
      value: value,
      items,
    }, {
      eventID: eventId,
    });
  };

  // When checkout page is opened
  const beginCheckout = (
    items: AnalyticProduct[],
    value = 0,
    cartId: number|null = null,
  ) => {
    // eslint-disable-next-line no-console
    console.log('beginCheckout', { items, value });

    window.gtag?.('event', 'begin_checkout', {
      currency: 'USD',
      items: items.map((item) => ({
        item_name: item.item_name,
        price: item.price,
        quantity: item.quantity,
      })),
      value,
      transaction_id: cartId,
    });

    window.fbq?.('track', 'InitiateCheckout', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      num_items: items.length,
      value,
    }, {
      eventID: cartId,
    });

    window.ttq?.track('InitiateCheckout', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      num_items: items.length,
      value,
    });
  };

  // After a successful purchase
  const purchase = (items: AnalyticProduct[], transactionId: string, value = 0, cartId: number) => {
    // eslint-disable-next-line no-console
    console.log('purchase', { items, transactionId, cartId, value, aid: aid.value });

    // Step 1 - add payment info
    window.gtag?.('event', 'add_payment_info', {
      currency: 'USD',
      payment_type: 'Credit Card', // TODO
      value: value,
      items,
      transaction_id: cartId,
    });

    window.fbq?.('track', 'AddPaymentInfo', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      value: value,
    }, {
      eventID: String(cartId),
    });

    // Step 2 - add shipping info
    window.gtag?.('event', 'add_shipping_info', {
      currency: 'USD',
      value: value,
      items,
    });

    // Step 3 - purchase
    window.gtag?.('event', 'purchase', {
      transaction_id: transactionId,
      value: value,
      currency: 'USD',
      items: items.map((item) => ({
        item_name: item.item_name,
        price: item.price,
        quantity: item.quantity,
      })),
    });

    window.fbq?.('track', 'Purchase', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      value: value,
      num_item: items.length,
    }, {
      eventID: String(cartId),
    });

    window.ttq?.track('Purchase', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      num_items: items.length,
      value,
    });

    window.VWO?.event('purchase', {
      purchaseValue: value,
    });
  };

  return {
    aid,
    campaign,
    viewContent,
    createAccount,
    addToCart,
    beginCheckout,
    purchase,
    viewPharmacy,
    removeFromCart,
    viewQuizStep,
    selectQuizAnswer,
  };
});
