import { apiUser } from '@api/user';
import { useFetch } from '@helpers/use-fetch';
import { FILE_TYPE } from '@models/quiz';
import { ResetPasswordPayload, UserLoginPayload, UserRegisterPayload, SessionInfoResponse } from '@models/user';
import { useAnalyticsStore } from '@stores/analytics';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useToast } from 'vue-toast-notification';

export const useUserStore = defineStore('user', () => {
  const toast = useToast();
  const info = ref<SessionInfoResponse>();
  const loading = ref(false);
  const files = ref<{ [type: string]: string | null }>({});
  const analyticsStore = useAnalyticsStore();

  const getProfileApi = useFetch(apiUser.getProfile);
  const updateProfileApi = useFetch(apiUser.updateProfile);
  const removePaymentMethodApi = useFetch(apiUser.removePaymentMethod);
  const getPaymentMethodApi = useFetch(apiUser.getPaymentMethod);

  const uploadMethods = {
    [FILE_TYPE.DOCUMENT]: apiUser.uploadDocument,
    [FILE_TYPE.PHOTO]: apiUser.uploadPhoto,
    [FILE_TYPE.INSURANCE_FRONT]: apiUser.uploadInsuranceFront,
    [FILE_TYPE.INSURANCE_BACK]: apiUser.uploadInsuranceBack,
  };

  const deleteMethods = {
    [FILE_TYPE.DOCUMENT]: apiUser.deleteDocument,
    [FILE_TYPE.PHOTO]: apiUser.deletePhoto,
    [FILE_TYPE.INSURANCE_FRONT]: apiUser.deleteInsuranceFront,
    [FILE_TYPE.INSURANCE_BACK]: apiUser.deleteInsuranceBack,
  };

  const getInfo = async () => {
    try {
      loading.value = true;
      const response = await apiUser.sessionInfo();
      info.value = response.data;
      window.csrfTokenName = response.data.csrfTokenName;
      window.csrfTokenValue = response.data.csrfTokenValue;
      return response;
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      loading.value = false;
    }
  };

  const register = async (data: UserRegisterPayload) => {
    try {
      data.campaign = analyticsStore.campaign;
      loading.value = true;
      const response = await apiUser.register(data);
      const infoResponse = await getInfo();
      if (infoResponse) {
        analyticsStore.createAccount(infoResponse.data);
      }
      return response;
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      loading.value = false;
    }
  };

  const login = async (data: UserLoginPayload) => {
    try {
      loading.value = true;
      const response = await apiUser.login(data);
      await getInfo();
      return response;
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      loading.value = false;
    }
  };

  const resetPassword = async (data: ResetPasswordPayload) => {
    try {
      loading.value = true;
      const response = await apiUser.resetPassword(data);
      toast.success(response.message);
      return response;
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      loading.value = false;
    }
  };

  const uploadFile = async (type: FILE_TYPE, data: FormData) => {
    try {
      loading.value = true;
      const response = await uploadMethods[type](data);
      files.value[type] = response.data;

      return response;
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      loading.value = false;
    }
  };

  const deleteFile = async (type: FILE_TYPE) => {
    try {
      loading.value = true;
      const response = await deleteMethods[type]();
      delete files.value[type];

      return response;
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      loading.value = false;
    }
  };

  return {
    info,
    loading,
    getInfo,
    register,
    login,
    resetPassword,
    files,
    uploadFile,
    deleteFile,
    getProfileApi,
    updateProfileApi,
    removePaymentMethodApi,
    getPaymentMethodApi,
  };
});
