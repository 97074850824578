import { request } from '@helpers/fetch';
import { Cart } from '@models/cart';
import { Response } from '@models/response';
import {
  UserRegisterPayload,
  UserLoginPayload,
  ResetPasswordPayload,
  UserLoginResponse,
  SessionInfoResponse,
  UserRegisterResponse,
  ResetPasswordResponse,
  ProfileResponse,
  ProfilePayload,
  ProfilePaymentMethod,
} from '@models/user';

export const apiUser = {
  sessionInfo(): Promise<Response<SessionInfoResponse>> {
    return request.post('/api/v1/users/session-info');
  },

  login(payload: UserLoginPayload): Promise<UserLoginResponse> {
    return request.post('/actions/users/login', payload);
  },

  register(payload: UserRegisterPayload): Promise<Response<UserRegisterResponse>> {
    return request.post('/api/v1/users/register', payload);
  },

  resetPassword(payload: ResetPasswordPayload): Promise<ResetPasswordResponse> {
    return request.post('/actions/users/send-password-reset-email', payload);
  },

  uploadPhoto(payload: FormData): Promise<Response<string>> {
    return request.postForm('/api/v1/document/photo', payload);
  },

  uploadDocument(payload: FormData): Promise<Response<string>>  {
    return request.postForm('/api/v1/document/document/document', payload);
  },

  uploadInsuranceFront(payload: FormData): Promise<Response<string>> {
    return request.postForm('/api/v1/document/document/insuranceCardFront', payload);
  },

  uploadInsuranceBack(payload: FormData): Promise<Response<string>> {
    return request.postForm('/api/v1/document/document/insuranceCardBack', payload);
  },

  deletePhoto(): Promise<Response<string>> {
    return request.remove('/api/v1/document/photo');
  },

  deleteDocument(): Promise<Response<string>>  {
    return request.remove('/api/v1/document/document/document');
  },

  deleteInsuranceFront(): Promise<Response<string>>  {
    return request.remove('/api/v1/document/document/insuranceCardFront');
  },

  deleteInsuranceBack(): Promise<Response<string>> {
    return request.remove('/api/v1/document/document/insuranceCardBack');
  },

  getProfile(): Promise<Response<ProfileResponse>> {
    return request.get('/api/v1/profile');
  },

  updateProfile(payload: ProfilePayload): Promise<Response<ProfileResponse>> {
    return request.put('/api/v1/profile', payload);
  },

  getPaymentMethod(): Promise<Response<ProfilePaymentMethod>> {
    return request.get('/api/v1/profile/payment-method');
  },

  removePaymentMethod(): Promise<Response<[]>> {
    return request.remove('/api/v1/profile/payment-method');
  },

  getPortalSession(): Promise<Response<any>> {
    return request.get('/api/v1/profile/portal-session');
  },

  getCartByCheckoutId(checkoutId: string): Promise<Response<Cart>> {
    return request.get(`/api/v1/cart/checkout/${checkoutId}`);
  },

  getProfessions(): Promise<Response<any>> {
    return request.get('/api/v1/users/professions');
  },

  chargebeeSync(): Promise<any> {
    return request.put('/api/v1/profile/chargebee-sync');
  },
};
