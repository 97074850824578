<script setup lang="ts">
import { computed, toRefs } from 'vue';

type Item = {
  label: string
  value: string
  isMain?: boolean
  hideZero?: boolean
}

type Props = {
  items: Item[]
}

const props = defineProps<Props>();
const { items } = toRefs(props);

const itemsList = computed(() => items.value.filter((item) => !item.hideZero));
</script>
<template>
  <div>
    <div
      v-for="item in itemsList"
      :key="item.label"
      class="mb-5"
      :data-testpl="`pharmacy-cart-${item.label.toLowerCase()}`"
    >
      <div :class="['grid grid-cols-2 text-right text-16 lg:text-18', item.isMain && 'font-bold']">
        <div
          class="mr-5 text-left"
          :data-testpl="`pharmacy-cart-${item.label.toLowerCase()}-label`"
        >
          {{ item.label }}
        </div>
        <div
          class="text-purple"
          :data-testpl="`pharmacy-cart-${item.label.toLowerCase()}-value`"
        >
          {{ item.value }}
        </div>
      </div>
    </div>
  </div>
</template>
